<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Addons</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Addons"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-watson-product-addons' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Addons
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="addons"
            no-data-text="No Archived Addons found"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: { name: "module-watson-products" },
        },
        {
          text: "Addons",
          disabled: false,
          exact: true,
          to: { name: "module-watson-product-addons" },
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      tableHeaders: [{ text: "Name", value: "name" }],
    };
  },

  computed: {
    addons() {
      let addons = this.$store.state.watson.products["archivedAddons"];

      if (this.searchTerm !== "") {
        addons = addons.filter(p => {
          const name = p.name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();
          return name.includes(searchTerm);
        });
      }

      return addons;
    },
  },
};
</script>
